import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'gatsby-plugin-intl'
import Fade from 'react-reveal/Fade'
import { Row, Col } from 'react-simple-flex-grid'
import { graphql } from 'gatsby'
import classNames from 'classnames'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import Img from 'gatsby-image'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import { Container, Heading, Image, ScrollArrow, Text } from '_atoms'
import { Quote, ProudCases, TextJSON } from '_molecules'
import { Layout } from '_templates'
import { SEO, ViewportObserver } from '_organisms'
import { AppContext } from '_context'
import { shuffleArray } from '_utils/shuffle'
import variables from '_config/css-variables'
import WoovitLogo from '_images/svgs/woovit-logo.inline.svg'
import communicationIcon from '_images/pages/portfolio/woovit/communication-icon.svg'
import communicationIconMobile from '_images/pages/portfolio/woovit/communication-icon-mobile.svg'
import { rawToJSON } from '_utils/functions'

import styles from './styles.module.css'

const resultsOptions = {
  renderMark: {
    bold: text => <strong>{text}</strong>,
  },
  renderNode: {
    paragraph: (node, children) => (
      <Text size="18" color="white">
        {children}
      </Text>
    ),
    hyperlink: (node, children) => {
      const { data } = node

      return (
        <a className={styles.resultsLink} href={data.uri} rel="noopener noreferrer" target="_blank">
          {children}
        </a>
      )
    },
  },
}

const challengeTopImagesSettings = [
  { top: true, right: true },
  { bottom: true, left: true },
]

const WoovitCaseStudy = ({ data }) => {
  const { setNavbarColor, setNavbarLight } = useContext(AppContext)
  const content = data.contentfulWoovitCaseStudy
  const { quote, proudCases } = content

  const [y, setY] = useState(0)

  // creator and controller icon animation total time + 200ms
  const challengeIconsDelay = content.challengeTopImages
    ? content.challengeTopImages.length * 200 + 350 + 200
    : 0

  // random order to show people from lists section
  let listsDelay = Array.from(new Array(content.featListsImages.length - 1), (_, n) => n + 1)
  listsDelay = shuffleArray(listsDelay)

  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    300
  )

  useEffect(() => {
    setNavbarColor('transparent')
    setNavbarLight(false)
  }, [setNavbarColor, setNavbarLight])

  useEffect(() => {
    if (y <= 90) {
      setNavbarColor('transparent')
    }
  }, [setNavbarColor, y])

  return (
    <Layout navbarColorOverride="transparent">
      <SEO
        title={content.pageTitle || 'Web &amp; Mobile App Design and Development Company'}
        description={
          content.pageDescription || 'Web &amp; Mobile App Design and Development Company'
        }
        thumbnail={content.pageThumbnail.file.url}
      />

      <main className={styles.hero}>
        <Container>
          <Row className={styles.heroRow}>
            <Col xs={12} sm={12} md={5} lg={5}>
              <div className={styles.heroContent}>
                <Fade distance="10%" bottom>
                  <WoovitLogo className={styles.heroLogo} />
                </Fade>
                <div className={styles.heroText}>
                  <Fade delay={200} distance="10%" bottom>
                    <TextJSON color="white" size="28" className={styles.heroParagraph}>
                      {rawToJSON(content.heroDescription.raw)}
                    </TextJSON>
                  </Fade>
                </div>
                <Fade delay={350} distance="20%" bottom>
                  <ScrollArrow className={styles.heroArrow} isButton scrollId="challenge" />
                </Fade>
              </div>
            </Col>
          </Row>
        </Container>

        <div className={styles.heroFixed}>
          <Fade delay={350} distance="20%" bottom>
            <Img
              fluid={content.heroImage.fluid}
              alt={content.heroImage.description}
              objectFit="contain"
              imgStyle={{ objectFit: 'contain', objectPosition: 'center right' }}
              fadeIn={false}
            />
          </Fade>
        </div>

        <div className={styles.heroImageMobile}>
          <Fade delay={350} distance="20%" bottom>
            <Img
              fluid={content.heroImage.fluid}
              alt={content.heroImageMobile.description}
              objectFit="contain"
              imgStyle={{ objectFit: 'contain', objectPosition: 'center right' }}
              fadeIn={false}
            />
          </Fade>
        </div>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['woovit-blue-accent'])
            setNavbarLight(false)
          }}
        />
      </main>

      <section className={styles.challenge} id="challenge">
        <Container className={styles.challengeContainer}>
          <Row align="middle" className={styles.challengeRow}>
            <Col xs={12} sm={12} md={5} lg={5}>
              <div className={styles.challengeContent}>
                <Fade distance="10%" bottom>
                  <Heading
                    type="h1"
                    color="woovitSecondary"
                    bold
                    className={classNames(styles.heading, styles.challengeHeading)}
                  >
                    {content.challengeTitle}
                    <span className={styles.dotPrimary}>.</span>
                  </Heading>
                </Fade>

                <div className={styles.text}>
                  <Fade delay={200} distance="10%" bottom>
                    <TextJSON color="dark" size="22" className={styles.paragraph}>
                      {rawToJSON(content.challengeDescription.raw)}
                    </TextJSON>
                  </Fade>
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={7} lg={7}>
              <div className={styles.challengeMedia}>
                {/*
                  waits main icons (creator and controller) animations finish and adds a 200ms delay
                  first image is a circle that comes with the first icon (top) and
                  then other icons are shown with 200ms interval clockwise
                */}
                {content.challengeTopImages &&
                  content.challengeTopImages.map(({ file, description }, ind) => (
                    <div key={file.url} className={styles.challengeImage}>
                      <Fade
                        delay={350 + 200 * ind}
                        distance="20%"
                        {...challengeTopImagesSettings[ind]}
                      >
                        <Image src={file.url} alt={description} className={styles.challengeImage} />
                      </Fade>
                    </div>
                  ))}
                {content.challengeImages &&
                  content.challengeImages.map(({ file, description }, ind) => (
                    <div key={file.url} className={styles.challengeImage}>
                      <Fade delay={challengeIconsDelay + 200 * (ind || 1)} distance="20%" bottom>
                        <Image src={file.url} alt={description} className={styles.challengeImage} />
                      </Fade>
                    </div>
                  ))}
              </div>
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['color-white'])
            setNavbarLight(true)
          }}
        />
      </section>

      <section className={styles.communication}>
        <Container className={styles.communicationContainer}>
          <Row align="middle" className={styles.communicationRow}>
            <Col xs={12} sm={12} md={7} lg={7}>
              <div className={styles.communicationMedia}>
                <div className={styles.communicationIcon}>
                  <Fade distance="20%" bottom>
                    <div className={styles.communicationIcon}>
                      <Image alt="" src={communicationIcon} className={styles.desktop} />
                      <Image alt="" src={communicationIconMobile} className={styles.mobile} />
                    </div>
                  </Fade>
                </div>
                <div>
                  {content.communicationImages &&
                    content.communicationImages.map(({ fluid, description }) => (
                      <div key={fluid.src} className={styles.communicationImage}>
                        <Fade delay={350} distance="20%" bottom>
                          <Img fluid={fluid} alt={description} />
                        </Fade>
                      </div>
                    ))}
                </div>
              </div>
            </Col>
            <Col xs={12} sm={12} md={5} lg={5}>
              <div className={classNames(styles.textRight, styles.communicationContent)}>
                <Fade distance="10%" bottom>
                  <Heading type="h4" color="woovitAccent" bold className={styles.heading}>
                    {content.communicationTitle}
                    <span className={styles.dot}>.</span>
                  </Heading>
                </Fade>

                <div className={styles.text}>
                  <Fade delay={200} distance="10%" bottom>
                    <TextJSON color="white" size="22" className={styles.paragraph}>
                      {rawToJSON(content.communicationDescription.raw)}
                    </TextJSON>
                  </Fade>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['woovit-blue-primary'])
            setNavbarLight(false)
          }}
        />
      </section>

      <div className={styles.featuresLight}>
        <section className={styles.feed}>
          <Container>
            <Row>
              <Col xs={12} sm={12} md={4} lg={4}>
                <div className={styles.feedContent}>
                  <Fade distance="10%" bottom>
                    <Heading type="h4" color="woovitPrimary" bold className={styles.heading}>
                      {content.featFeedTitle}
                      <span className={styles.dotPrimary}>.</span>
                    </Heading>
                  </Fade>
                  <div className={styles.feedText}>
                    <Fade delay={200} distance="10%" bottom>
                      <TextJSON color="woovitPrimary" size="22" className={styles.feedParagraph}>
                        {rawToJSON(content.featFeedDescription.raw)}
                      </TextJSON>
                    </Fade>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>

          <div className={styles.feedFixed}>
            {content.featFeedImagesDesktop.map(({ fluid, description }, ind) => (
              <div key={fluid.src} className={styles.feedFixedImage}>
                <Fade delay={350 + 250 * ind} distance="20%" bottom>
                  <Img
                    fluid={fluid}
                    alt={description}
                    objectFit="contain"
                    imgStyle={{ objectFit: 'contain', objectPosition: 'center right' }}
                    fadeIn={false}
                  />
                </Fade>
              </div>
            ))}
          </div>

          <div className={styles.feedImageMobile}>
            <Fade delay={350} distance="20%" bottom>
              <Img
                fluid={content.featFeedImageMobile.fluid}
                alt={content.featFeedImageMobile.description}
                objectFit="contain"
                imgStyle={{ objectFit: 'contain', objectPosition: 'center' }}
                fadeIn={false}
              />
            </Fade>
          </div>
        </section>

        <section className={styles.report}>
          <Container>
            <Row className={styles.reportRow} align="middle">
              <Col xs={12} sm={12} md={8} lg={8}>
                <div className={styles.reportMedia}>
                  {content.featReportImages.map(({ fluid, description }, ind) => (
                    <div key={fluid.src} className={styles.reportImage}>
                      <Fade delay={350 + 250 * ind} distance="20%" bottom>
                        <Img
                          fluid={fluid}
                          alt={description}
                          objectFit="contain"
                          imgStyle={{ objectFit: 'contain' }}
                          fadeIn={false}
                        />
                      </Fade>
                    </div>
                  ))}
                </div>
              </Col>
              <Col xs={12} sm={12} md={4} lg={4}>
                <div className={classNames(styles.textRight, styles.reportContent)}>
                  <Fade distance="10%" bottom>
                    <Heading type="h4" color="woovitPrimary" bold className={styles.heading}>
                      {content.featReportTitle}
                      <span className={styles.dotPrimary}>.</span>
                    </Heading>
                  </Fade>
                  <div className={styles.reportText}>
                    <Fade delay={200} distance="10%" bottom>
                      <TextJSON color="woovitPrimary" size="22" className={styles.reportParagraph}>
                        {rawToJSON(content.featReportDescription.raw)}
                      </TextJSON>
                    </Fade>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['woovit-light'])
            setNavbarLight(true)
          }}
        />
      </div>

      <div className={styles.featuresDark}>
        <section className={styles.interest}>
          <Container>
            <Row align="middle">
              <Col xs={12} sm={12} md={4} lg={4}>
                <div className={styles.interestContent}>
                  <Fade distance="10%" bottom>
                    <Heading type="h4" color="white" bold className={styles.heading}>
                      {content.featInterestTitle}
                      <span className={styles.dotPrimary}>.</span>
                    </Heading>
                  </Fade>
                  <div className={styles.interestText}>
                    <Fade delay={200} distance="10%" bottom>
                      <TextJSON color="white" size="22" className={styles.reportParagraph}>
                        {rawToJSON(content.featInterestDescription.raw)}
                      </TextJSON>
                    </Fade>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={8} lg={8}>
                <div className={styles.interestMedia}>
                  <Fade delay={350} distance="20%" bottom>
                    <Img
                      fluid={content.featInterestImage.fluid}
                      alt={content.featInterestImage.description}
                      objectFit="contain"
                      imgStyle={{ objectFit: 'contain' }}
                      fadeIn={false}
                    />
                  </Fade>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className={styles.lists}>
          <Container>
            <Row align="middle" className={styles.listsRow}>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className={styles.listsMedia}>
                  {/* remove the delay from first image (bg) and randomize for all other images */}
                  {content.featListsImages.map(({ fluid, description }, ind) => (
                    <div key={fluid.src} className={styles.listsImage}>
                      <Fade
                        delay={ind === 0 ? ind : listsDelay[ind - 1] * 200}
                        distance="20%"
                        bottom
                      >
                        <Img
                          fluid={fluid}
                          alt={description}
                          objectFit="contain"
                          imgStyle={{ objectFit: 'contain' }}
                          fadeIn={false}
                        />
                      </Fade>
                    </div>
                  ))}
                </div>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <div className={classNames(styles.textRight, styles.listsContent)}>
                  <Fade distance="10%" bottom>
                    <Heading type="h4" color="white" bold className={styles.heading}>
                      {content.featListsTitle}
                      <span className={styles.dotPrimary}>.</span>
                    </Heading>
                  </Fade>
                  <div className={styles.listsText}>
                    <Fade delay={200} distance="10%" bottom>
                      <TextJSON color="white" size="22" className={styles.listsParagraph}>
                        {rawToJSON(content.featListsDescription.raw)}
                      </TextJSON>
                    </Fade>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['woovit-gradient'])
            setNavbarLight(false)
          }}
        />
      </div>

      {quote && (
        <section className={styles.quote}>
          <div className={styles.quoteContentWrapper}>
            <Fade distance="10%" bottom>
              <Quote
                mobileComplete
                color="woovitAccent"
                text={quote.text.internal.content}
                user={quote.user}
                userPhoto={quote.userImage.fixed}
                userJob={quote.jobDescription}
              />
            </Fade>
          </div>

          <ViewportObserver
            scroll={y}
            onViewport={() => {
              setNavbarColor(variables['woovit-light'])
              setNavbarLight(true)
            }}
          />
        </section>
      )}

      <section className={styles.results}>
        <div className={styles.resultsBlock}>
          <Container className={styles.resultsContainer}>
            <div className={styles.resultsItem}>
              <Fade distance="10%" bottom>
                <Heading type="h4" bold color="white" className={styles.resultsHeading}>
                  <FormattedMessage id="casesCommon.results" />
                  <span className={styles.dotPrimary}>.</span>
                </Heading>
              </Fade>
            </div>

            {content.resultsImages &&
              content.resultsImages.map(({ file, description }, ind) => (
                <div key={file.url} className={styles.resultsItem}>
                  <Fade delay={350 + 200 * (ind + 1) - 50} distance="10%" bottom>
                    <Image src={file.url} alt={description} className={styles.resultsImage} />
                  </Fade>
                  <div className={styles.resultsContent}>
                    <Fade delay={350 + 200 * (ind + 1)} distance="10%" bottom>
                      <div className={styles.resultsText}>
                        {documentToReactComponents(
                          rawToJSON(content[`results${ind + 1}`].raw),
                          resultsOptions
                        )}
                      </div>
                    </Fade>
                  </div>
                </div>
              ))}
          </Container>
        </div>

        <ViewportObserver
          scroll={y}
          onViewport={() => {
            setNavbarColor(variables['woovit-blue-primary'])
            setNavbarLight(false)
          }}
        />
      </section>

      {proudCases && (
        <div className={styles.portfolio}>
          <ProudCases currentProject="Woovit" caseStudies={proudCases} extendedCTA />

          <ViewportObserver
            scroll={y}
            onViewport={() => {
              setNavbarColor(variables['color-white'])
              setNavbarLight(true)
            }}
          />
        </div>
      )}
    </Layout>
  )
}

export const query = graphql`
  query WoovitCaseStudyContent($locale: String) {
    contentfulWoovitCaseStudy(fields: { localeKey: { eq: $locale } }) {
      pageTitle
      pageDescription
      pageThumbnail {
        file {
          url
        }
      }
      heroDescription {
        raw
      }
      heroImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      heroImageMobile {
        description
      }
      challengeTitle
      challengeDescription {
        raw
      }
      challengeImages {
        description
        file {
          url
        }
      }
      challengeTopImages {
        description
        file {
          url
        }
      }
      communicationTitle
      communicationDescription {
        raw
      }
      communicationImages {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      featFeedTitle
      featFeedDescription {
        raw
      }
      featFeedImagesDesktop {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      featFeedImageMobile {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      featReportTitle
      featReportDescription {
        raw
      }
      featReportImages {
        description
        fluid(maxHeight: 880) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      featInterestTitle
      featInterestDescription {
        raw
      }
      featInterestImage {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      featListsTitle
      featListsDescription {
        raw
      }
      featListsImages {
        description
        fluid {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      quote {
        id
        jobDescription
        user
        text {
          internal {
            content
          }
        }
        userImage {
          fixed(width: 64, height: 64, quality: 80) {
            src
            srcSet
          }
        }
      }
      results1 {
        raw
      }
      results2 {
        raw
      }
      results3 {
        raw
      }
      resultsImages {
        description
        file {
          url
        }
      }
      proudCases {
        id
        title
        main
        platforms {
          internal {
            content
          }
        }
        projectColor
        projectName
        thumbnail {
          description
          fixed {
            ...GatsbyContentfulFixed_withWebp_noBase64
          }
          file {
            details {
              image {
                width
                height
              }
            }
          }
        }
      }
    }
  }
`

WoovitCaseStudy.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

export default WoovitCaseStudy
